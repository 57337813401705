export default {
  "lt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lietuviškai"])},
  "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anglų"])},
  "ru": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rusiškai"])},
  "payment": {
    "montonio_payments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montonio"])},
    "pp_express": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PayPal Express Checkout"])},
    "free_checkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nemokamas užsakymas"])},
    "header_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mokėjimas sėkmingai atliktas"])},
    "go_home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grįžti į pagrindinį puslapį"])}
  },
  "common": {
    "back_to_website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grįžti į pradinį puslapį"])},
    "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ir"])},
    "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["arba"])},
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naudotojo vardas"])},
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grižti į pradinį puslapį"])},
    "find_out_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sužinokite daugiau"])},
    "watch_the_presentation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žiūrėti pristatymą"])},
    "of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iš"])},
    "private": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asmeniui"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atgal"])},
    "company_types": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kursų taikymo sritis"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Norint užsakyti specializuotus mokymus savo įmonės darbuotojams, prašome pateikti šią informaciją: darbuotojų kiekis, pageidaujama mokymų tema ir mokymų apimtis ar trukmė. Tai padės mums susisiekti ir pateikti jums geriausią pasiūlymą, atitinkantį jūsų poreikius."])}
    },
    "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sužinokite daugiau"])},
    "business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organizacijai"])},
    "privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privatumo politika"])},
    "cookie_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slapukų politika"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atsijungti"])},
    "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apžvalga"])},
    "my_courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mano kursai"])},
    "purchases_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pirkimų istorija"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nustatymai"])},
    "old_app": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sena versija"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profilis"])},
    "recommended_courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jums rekomenduojami kursai"])},
    "popular_courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kursai pagal populiarumą"])},
    "certificate_validity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sertifikato galiojimas"])},
    "completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atlikta"])},
    "hello": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sveiki"])},
    "my_certificates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mano sertifikatai"])},
    "connect_other_device": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prijungti antrą įrenginį"])},
    "course_duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kursų trukmė"])},
    "course_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mokymų pradžia"])},
    "practice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Praktika"])},
    "in_the_sim_room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vykdoma simuliacinėje klasėje"])},
    "recieve_certificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sėkmingai baigę kursą gausite pažymėjimą"])},
    "certificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sertifikatas"])},
    "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sukurta"])},
    "hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["val."])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtruoti"])},
    "courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kursai"])},
    "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apmokėjimas"])},
    "payment_checkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Užbaigti mokėjimą"])},
    "cart_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jūsų krepšelis tusčias."])},
    "send_request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siųsti užklausą"])},
    "faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dažniausiai užduodami klausimai"])},
    "faq_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dažniausiai užduodamų klausimų nėra."])},
    "looking_course_for_bigger_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domina kursai didesnėms grupėms?"])},
    "courses_for_bussiness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kursai įmonėms ir organizacijoms"])},
    "courses_for_business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kursai įmonėms ir organizacijoms"])},
    "courses_by_business_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kursai pagal įstaigos tipą"])},
    "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopijuoti"])},
    "or_copy_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arba kopijuokite nuorodą"])},
    "share_on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dalinkite per"])},
    "share_course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dalintis kursu"])},
    "general_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Įvyko klaida, prašome pamėginti dar kartą."])},
    "icons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ikonėlės"])},
    "cart_is_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prekių krepšelis tusčias"])},
    "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apmokėti"])},
    "cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krepšelis"])},
    "total_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viso mokėti"])},
    "by_completing_the_payment_you_agree_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Užbaigiant mokėjimą jūs sutinkate su"])},
    "terms_and_conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taisyklėmis ir sąlygomis"])},
    "get_in_touch_easily_by_filling_out_the_contact_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lengvai susisiekite užpildę kontaktų formą"])},
    "request_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UŽklausos forma"])},
    "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaktai"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagalba"])},
    "faq_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["D.U.K"])},
    "hybridlab_contacts_and_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HybridLab kontaktai ir rekvizitai"])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kraunama"])},
    "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausimas"])},
    "left_attempts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liko bandymų"])},
    "mark_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark the question"])},
    "edit_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redaguoti klausimą"])},
    "no_subjects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Šiuo metu temų nėra."])},
    "remind_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Priminti slaptažodį"])},
    "how_it_works": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaip tai veikia?"])},
    "login_to_learning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prisijunkite prie mokymų"])},
    "or_login_with_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arba prisijunkite savo naudotojo vardu"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prisijungti"])},
    "incorrect_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neteisingas vartotojo vardas arba slaptažodis"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klaida"])},
    "dont_have_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dar neturite paskyros?"])},
    "create_new_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurti naują paskyrą."])},
    "login_with_google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prisijungti su Google"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El. pašto adresas"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slaptažodis"])},
    "your_certificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jūsų įgytas sertifikatas"])},
    "certificate_valid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sertifikato galiojimas"])},
    "certificate_not_valid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sertifikatas negalioja"])},
    "course_end_result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurso baigimo rezultatas"])},
    "certificate_author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sertifikatą išdavė"])},
    "course_material": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurso medžiaga"])},
    "show_end_courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rodyti tik baigtus kursus"])},
    "purchases_history_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pirkimų nėra"])},
    "order_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Užsakymo ID:"])},
    "create_invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suformuoti sąskaitą faktūrą"])},
    "payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mokėjimo būdas"])},
    "change_email_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keisti el. paštą"])},
    "phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefono numeris"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miestas"])},
    "timezone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laiko juosta"])},
    "preferred_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pageidaujama kalba"])},
    "city_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pasirinkite miestą"])},
    "country_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pasirinkite šalį"])},
    "language_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pasirinkite kalbą"])},
    "timezone_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pasirinkite laiko juostą"])},
    "organisation_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Įveskite darbovietę (min. 3 simboliai)"])},
    "position_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Įveskite pareigas (min. 3 simboliai)"])},
    "change_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keisti slaptažodį"])},
    "organizational_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organizaciniai nustatymai"])},
    "workplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Darbovietė"])},
    "responsibilities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pareigos"])},
    "specify_the_position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nurodykite pareigas"])},
    "enter_the_name_of_the_workplace_or_organization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Įveskite darbovietės ar organizacijos pavadinimą"])},
    "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lytis"])},
    "birthday_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gimimo data"])},
    "select_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pasirinkite šalį"])},
    "select_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pasirinkite miestą, kuriame gyvenate"])},
    "we_will_remind_you_about_the_courses_according_to_the_set_time_zone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagal nustatytą laiko juostą priminsime apie kursus"])},
    "show_valid_certificates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rodyti tik galiojančius sertifikatus"])},
    "interested_in_courses_for_larger_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domina kursai didesnėms grupėms?"])},
    "fill_out_the_inquiry_form_and_we_will_contact_you_shortly_with_an_individual_offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Užpildykite užklausos formą, o mes su jumis susisieksime artimiausiu metu ir pateiksime individualų pasiūlymą."])},
    "fill_out_the_request_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pildyti užklausos formą"])},
    "all_courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visi kursai"])},
    "add_to_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dėti į krepšelį"])},
    "general_part": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bendroji dalis"])},
    "theoretical_material": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teorinė medžiaga"])},
    "tests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testai"])},
    "select_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pasirinkite vieną"])},
    "about_course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apie kursą"])},
    "courses_for_business_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kelkite savo įmonės ar organizacijos darbuotojų kvalifikaciją pagal HybridLab patvirtintą mokymų metodiką."])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registracija"])},
    "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vardas"])},
    "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pavardė"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tęsti"])},
    "register_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El. pašto adresas"])},
    "register_error": {
      "username_taken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vartotojo vardas jau užimtas"])},
      "email_taken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El. pašto adresas jau naudojamas"])},
      "password_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slaptažodį turi sudaryti mažiausiai 8 simboliai"])}
    },
    "create_pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sukurkite slaptažodį"])},
    "repeat_pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pakartokite slaptažodį"])},
    "you_already_have_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jau turite paskyrą?"])},
    "terms_of_use": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naudojimo salygomis"])},
    "i_have_met_and_agree_with": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Susipažinau ir sutinku su"])},
    "user_register_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jūsų paskyra sėkmingai sukurta"])},
    "upcoming_courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artėjantys kursai"])},
    "all_my_courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mano visi kursai"])},
    "lastest_solve_courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paskutiniai spręsti kursai"])},
    "share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dalintis"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Šalis"])},
    "link_material": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuoroda"])},
    "video_material": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video medžiaga"])},
    "document_material": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumentas"])},
    "extlms_room_material": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalendorius"])},
    "algorithm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algoritmas"])},
    "quiz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testas"])},
    "questionnaire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klausimynas"])},
    "forum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forumas"])},
    "hvp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interaktyvus turinys"])},
    "show_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rodyti daugiau"])},
    "subscription_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vidinių mokymų užsakymas"])},
    "subscription_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Norint užsakyti specializuotus mokymus savo įmonės darbuotojams, prašome pateikti šią informaciją: darbuotojų kiekis, pageidaujama mokymų tema ir mokymų apimtis ar trukmė. Tai padės mums susisiekti ir pateikti jums geriausią pasiūlymą, atitinkantį jūsų poreikius.\n\n"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Išsaugoti"])},
    "upload_photo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pakeisti nuotrauką"])},
    "paypal_redirection_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Būsite nukreipti į PayPal apmokėjimo sistemą, kurioje užbaigsite mokėjimą."])},
    "change_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keisti informacija"])},
    "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keisti"])},
    "login_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arba prisijunkite savo naudotojo vardu"])},
    "login_with_facebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prisijungti su Facebook"])},
    "are_you_have_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jau turite paskyrą?"])},
    "subscription": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeigu domina kursų prenumerata arba neatitinka jūsų poreikių prašome susisiekti."])}
    },
    "upcoming_courses_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artėjančių kursų nėra."])},
    "lastest_solve_courses_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paskutinių sprestų kursų nėra."])},
    "apply_a_discount_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pritaikyti nuolaidos kodą"])},
    "discount_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuolaidos kodas"])},
    "your_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jūsų vardas"])},
    "your_firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jūsų vardas"])},
    "your_lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jūsų pavardė"])},
    "your_telephone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jūsų telefono numeris"])},
    "your_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jūsų el. paštas"])},
    "your_birthdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jūsų gimimo diena"])},
    "your_gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pasirinkite savo lytį"])},
    "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pasirinkti"])},
    "select_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pasirinkite temą"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žinutė"])},
    "get_in_touch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Susisiekite"])},
    "in_this_subject_you_learn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Šioje temoje išmoksite"])},
    "forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pamiršote savo naudotojo vardą ar slaptažodį?"])},
    "reminder_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Priminimas išsiųstas"])},
    "try_again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bandyti iš naujo"])},
    "change_profile_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keisti profilio informacija"])},
    "password_settings_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagal nustatytą laiko juostą priminsime apie kursus"])},
    "change_account_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["redaguoti profilį"])},
    "change_account_information_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["edit"])},
    "no_courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagal šiuos kriterijus nepavyko rasti ne vieno kurso."])},
    "no_enrolled_courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neturite jokių kursų, kuriuos galėtumėte spręsti"])},
    "save_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Išsaugoti pakeitimus"])},
    "repeat_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pakartokite slaptažodį"])},
    "create_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sukurkite slaptažodį"])},
    "email_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El. paštao adresas"])},
    "enter_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Įveskite naują slaptažodį"])},
    "repeat_new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pakartokite naują slaptažodį"])},
    "situation_material": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Situacija"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pavyko"])},
    "add_to_cart_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produktas sėkmingai pridėtas"])},
    "add_to_cart_modal": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kursas sėkmingai pridėtas į krepšelį"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Norite tęsti apsipirkimą ar eiti į krepšelį?"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tęsti apsipirkimą"])},
      "go_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peržiūrėti krepšelį"])}
    },
    "favourites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mėgstamiausi"])},
    "buy_as_company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juridinis asmuo"])},
    "company_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Įmonės pavadinimas"])},
    "company_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Įveskite įmonės pavadinimą"])},
    "company_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Įmonės kodas"])},
    "company_code_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Įveskite įmonės kodą"])},
    "company_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Įmonės adresas"])},
    "company_address_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Įveskite įmonės adresą"])},
    "company_vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Įmonės PVM kodas"])},
    "company_vat_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Įveskite įmonės PVM kodą (neprivaloma)"])},
    "buyer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pirkėjas"])},
    "sum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suma"])},
    "courses_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " kursai(-ų)"])},
    "go_course_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eiti į kursų sąrašą"])},
    "filter_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtruoti pagal"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["atšaukti"])},
    "cancel_upper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atšaukti"])},
    "edit_photo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redaguoti nuotrauką"])},
    "topics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temos"])},
    "server_timezone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serverio laiko juosta (UTC)"])},
    "welcome_user": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sveiki, ", _interpolate(_named("name"))])}
  },
  "reviews": {
    "leave_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palikti atsiliepimą"])},
    "add_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pridėti atsiliepimą"])},
    "incorrect_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suvesti neteisingi duomenys"])},
    "error_while_trying_to_save_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Įvyko klaida bandant išsaugoti atsiliepimą"])}
  },
  "home-slider": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hibridinis mokymasis"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes pristatome hibridinį mokymosi metodą, kurio efektyvumas įrodytas moksliniais tyrimais. Sed ipsius honestatis decore laudandi id totum."])}
  },
  "covid_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Parsisiųskite mobiliąją programėlę <a href=\"", _interpolate(_named("to")), "\" target=\"_blank\">čia</a>"])},
  "navigation": {
    "search_certificates_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ieškoti sertifikatų"])},
    "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ieškoti kursų"])},
    "how-it-works": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaip veikia"])},
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apie HybridLab"])},
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagrindinis"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaktai"])},
    "courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kursai"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prisijungti"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registracija"])},
    "eshop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parduotuvė"])},
    "opportunities_for_institutions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Galimybės įstaigoms"])},
    "forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slaptažodžio keitimas"])},
    "projects": {
      "dropdown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ES Projektai"])},
      "in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vykdomi"])},
      "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Įgyvendinti"])}
    }
  },
  "megamenu": {
    "private": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privatiems"])},
      "links": [
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visi kursai"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kursai pagal sritį"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kursai pagal specialybę"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kursai pagal darbo vietą"])}
        }
      ],
      "course_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kursai pagal "])}
    },
    "business": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verslui"])},
      "links": [
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kursai įmonėms ir organizacijoms"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HybridLab verslo galimybės"])}
        }
      ]
    }
  },
  "home": {
    "courses-by-filter": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kursai pagal filtrus"])}
    },
    "courses-by-category": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kursai pagal kategorijas"])},
      "show-courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žiūrėti kursus"])}
    },
    "courses-by-popularity": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kursai pagal populiarumą"])}
    },
    "advantages": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mokymosi pasaulį padaryti geresniu"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuriame inovatyvius mokymosi sprendimus, kuriuos lengviau įsisavinti ir patogiau pasiekti."])},
      "types": [
        {
          "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kursai pagal patvirtintą mokymų metodiką"])},
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" HybridLab® leidžia mokytis ir praktikuotis bet kur ir bet kada, pagal jūsų tvarkaraštį ir poreikius."])}
        },
        {
          "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Įgykite sertifikatą"])},
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HybridLab® suteikia galimybę gauti sertifikatus ir įrodyti savo įgūdžius bei žinias."])}
        },
        {
          "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuotolinis mokymasis"])},
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HybridLab® leidžia mokytis nuotoliniu būdu, per virtualias simuliacijas, kurios suteikia autentišką ir interaktyvią mokymosi patirtį."])}
        },
        {
          "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kelkite organizacijos darbuotojų kvalifikaciją"])},
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HybridLab® padeda organizacijoms tobulinti savo darbuotojų žinias ir įgūdžius, užtikrinant aukštą kvalifikaciją ir sėkmingus rezultatus."])}
        }
      ]
    },
    "clients": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klientai"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bendradarbiaujame su daugiau nei 20 žinomų kompanijų."])}
    },
    "contactus": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sužinokite daugiau apie galimybes įstaigoms ar verslui"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palikite savo telefono numerį, o mūsų komanda susisieks su jumis artimiausiu metu ir papasakos jums apie visas galimybes."])},
      "phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefono nr."])},
      "call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pateikti"])}
    },
    "types": {
      "find-out-more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sužinokite daugiau"])},
      "types": [
        {
          "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Galimybės gydymo ir viešosioms įstaigoms"])},
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Didelėms grupėms taikomi kursai ir individuali kainodara."])}
        },
        {
          "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Galimybės verslui"])},
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pritaikykite HybridLab įrankius ir mokymosi metodiką savo verslui."])}
        }
      ]
    }
  },
  "discover-courses": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atraskite kursus"])},
    "show-more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rodyti daugiau"])},
    "filter-by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ieškoma pagal:"])}
  },
  "course": {
    "show-more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žiūrėti visus"])},
    "free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nemokamai"])},
    "sidebar_types": [
      
    ],
    "about_course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apie kursą"])},
    "methodology": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apie kursą"])},
    "abilities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Įgūdžiai, kuriuos įgysite"])},
    "subjects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurso temos"])},
    "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atsiliepimai"])},
    "faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DUK"])},
    "reviews_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atsiliepimų šiuo metu nėra."])},
    "purpose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurso tikslas"])},
    "tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurso uždaviniai"])},
    "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurso apžvalga"])},
    "how_to_study": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaip mokytis"])},
    "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klausimynai"])},
    "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pradžia:"])},
    "end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pabaiga:"])},
    "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pasirinkite"])},
    "events": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n")), " renginiai"])},
    "subscription": {
      "employee_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Darbuotojų skaičius įmonėje ar įstaigoje"])},
      "preliminary_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preliaminari prenumeratos kaina"])},
      "employees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Darbuotojai"])},
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaina"])},
      "price_per_person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EU/žmogui"])}
    },
    "results": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " rezultatai(-ų)"])},
    "activity": {
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nepavyko surasti aktyvumo"])},
      "session_expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jūsų sesijos laikas baigėsi"])},
      "session_refresh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atnaujinti sesiją"])},
      "back_to_course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grįžti į kursą"])}
    }
  },
  "how-it-works": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaip tai veikia?"])},
    "available_on_mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pasiekiama mobiliajame telefone"])},
    "sub-header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si sine causa? quae fuerit causa, nollem me ab illo."])},
    "advantages": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mokymosi pasaulį padaryti geresniu"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuriame inovatyvius mokymosi sprendimus, kuriuos lengviau įsisavinti ir patogiau pasiekti."])},
      "types": [
        {
          "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mokykitės kur jums patogu"])},
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HybridLab metodas leidžia jums pasiekti visą informaciją telefone ar kompiuteryje, bei mokytis nuotoliniu būdu prisitaikant prie jūsų tvarkaraščio ir poreikių."])}
        },
        {
          "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Įgykite sertifikatą"])},
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HybridLab suteikia galimybę įgyti oficialų mediciinos profilio sertifikatą užbaigus mokymus."])}
        },
        {
          "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarptautinė praktika"])},
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HybridLab praktikoje naudojamos naujausios simuliacinės klasės, su visa reikalinga įranga."])}
        },
        {
          "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kelkite organizacijos darbuotojų kvalifikaciją"])},
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HybridLab yra puiki galimybė pagerinti organizacijos darbuotojų kvalifikaciją. Išlikite konkurencingi nuolat tobulėdami!"])}
        }
      ]
    },
    "application": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hibridinis mokymasis neišeinant iš namų"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HybridLab® mokymai gali vykti, bet kurioje vietoje arba priklausomai nuo kurso, nuotoliniu būdu. Norint mokytis hibridiniu būdu, reikalinga:"])},
      "steps": [
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parsisiųsti mobilią aplikaciją"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pasirinkti kursą kurį norite mokytis"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peržiūrėti kurso medžiagą, pasitikrinti savo žinias-išspręsti testą"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Užsiregistruoti praktiniams mokymams"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pabaigti praktinius mokymus, gauti dėstytojo įvertinimą ir baigimo sertifikatą"])}
        }
      ],
      "learn-more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sužinokite daugiau apie hibridinį mokymą"])}
    },
    "laboratories": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hibridiniai mokymai HybridLab laboratorijose"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hibridiniai mokymai simuliacinėse / hibridinėse mokymo klasėse Hybridlab® puikus sprendimas mokymosi proceso optimizavimui."])},
      "items": [
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lankstumas"])},
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mokymai suteikia daugiau laisvės ir lankstumo. Teorinę mokymų dalį galima peržiūrėti nuotoliniu būdu, studentams patogiu metu. Praktiniams užsiėmimams pasirinkti patogiausią laiką ir bendramokslius."])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individualus mokymosi tempas"])},
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Praktiniai mokymai vyksta mažose, trijų asmenų grupelėse, kiekvienas besimokantysis mokosi savo tempu. Taip lengviau įsisavinti medžiagą, pakartoti sunkesnius dalykus ar greičiau peržiūrėti paprastesnius."])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naujas mokymosi metodas"])},
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Studentai naudoja įvairias mokymosi priemones ir metodus. Derinama efektyvi ir mokslo įrodyta apverstos klasės (flipped class) ir mokymosi poroje (peer-to-peer) mokymosi metodika."])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Praktinis mokymasis"])},
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naudodami interaktyvius algoritmai, studentai praktiškai sprendžia situacijas, įgyja praktikoje iš karto pritaikomas žinias ir įgūdžius."])}
        }
      ]
    }
  },
  "based_on_AHA_recommendation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remiantis AHA rekomendacijomis"])},
  "qr": {
    "0": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobilioje aplikacijoje pradėkite susitikimą"])}
    },
    "1": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pasirinkite “Prijungti išorinius įrenginius” arba ieškokite"])}
    },
    "2": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuskenuokite žemiau esantį QR kodą"])}
    }
  },
  "about": {
    "about_hybrid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apie HybridLab"])},
    "header": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuotolinio mokymosi ir praktinio modeliavimo sintezė."])}
    },
    "map": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kad mokymosi pasaulis būtų geresnis"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuriame geresnius mokymosi sprendimus, kurie yra lengviau prieinami ir patogesni."])},
      "items": {
        "0": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kelionės"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keliaudami mes visada ieškojome to, ko trūksta ir ką dar reikėtų nuveikti, sukurti ir tobulinti besimokančiame pasaulyje."])}
        },
        "1": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pasaulinis mąstas"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Šiandien mūsų pagrindinis tikslas yra sudaryti sąlygas praktikai ir visą parą bei nuotoliniu būdu suteikti techninių ir netechninių įgūdžių, ypač medicinos švietimo srityje."])}
        },
        "2": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tikslas"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Štai kodėl mes kuriame ir siūlome naujoviškus mokymosi ir mokymo metodus bei technologinius sprendimus."])}
        }
      }
    },
    "applies_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taikoma norint nustatyti atokiose, ribotų išteklių srityse."])}
  },
  "activity": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“HybridLab®” veikla"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["„HybridLab®“ mokymui nereikia tiesioginio instruktoriaus dalyvavimo ir jis gali būti bet kurioje pageidaujamoje vietoje. Prieš atvykstant į „HybridLab®“ studentai ruošiasi naudodamiesi iš anksto parengtomis el. mokymosi sistemomis. Vietoje jie gauna instrukcijas ir nurodymus, ką ir kaip daryti, naudodami specialiai sukurtus algoritmus su reikiama mokymo įranga. Instruktorius bet kuriuo metu stebi ir vertina mokinių pasiekimus per atstumą, kol studentai mokosi „HybridLab®“ klasėje."])},
    "items": {
      "0": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elektroninis mokymasis"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HybridLab® leidžia mokytis neprisijungiant prie tiesioginio instruktoriaus. Mokymasis gali vykti bet kokioje norimoje vietoje."])}
      },
      "1": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rankos ant simuliacijos"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HybridLab® suteikia galimybę mokytis per virtualias simuliacijas, kur nereikia tiesioginio instruktoriaus dalyvavimo. Tai leidžia įgyti praktinius įgūdžius su bet kokia mokymo įranga."])}
      },
      "2": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarpusavio mokymas"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HybridLab® suteikia galimybę instruktoriui stebėti ir vertinti mokinių pasiekimus nuotoliniu būdu, kol jie dirba „HybridLab®“ klasėje."])}
      },
      "3": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remiantis sėkmingo mokymosi algoritmais"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HybridLab® naudoja specialiai sukurtus algoritmus, kurie leidžia mokiniams gauti instrukcijas ir nurodymus, ką ir kaip daryti, tinkamai pritaikytiems jų mokymui."])}
      }
    }
  },
  "contacts": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaktai"])},
    "desc_part": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turite klausimą? Pabandykite jį surasti tarp"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([", o jei nerasite, tuomet norėtume jį išgirsti iš jūsų."])}
    },
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Susisiekite žemiau esančiais kontaktais arba atsiųskite mums užklausą užpildę formą ir mes atsakysime jums kuo greičiau."])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Susisiekite užpildę formą"])}
  },
  "howitworks": {
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuriame inovatyvius mokymosi sprendimus, kuriuos lengviau įsisavinti ir patogiau pasiekti."])}
  },
  "commoon": {
    "forgot_password_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slaptažodžio priminimas išsiųstas el. pašto adresu adresą, prašome patikrinti pašto dėžutę ir sekti instrukcijas."])}
  },
  "footer": {
    "private": {
      "discover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El. parduotuvė"])},
      "how_it_works": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaip veikia"])}
    },
    "business": {
      "business_and_institutions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Galimybės organizacijoms"])},
      "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prenumerata"])}
    },
    "hybridlab": {
      "how_it_works": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaip veikia"])},
      "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apie mus"])},
      "projects_in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vykdomi projektai"])},
      "projects_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Įgyvendinti projektai"])},
      "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaktai"])}
    },
    "rights_reserved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visos teisės saugomos. "])}
  },
  "banner": {
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atsisiųsk"])},
    "our_app": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mūsų programą"])},
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sveiki atvykę į kursą! Tikime, kad mokymai atitiks jūsų lūkesčius."])}
  },
  "course_sidebar": {
    "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trukmė"])},
    "start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pradžios laikas"])},
    "end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pabaigos laikas"])},
    "location_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vietos adresas"])},
    "location_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vietos pavadinimas"])}
  },
  "change_password": {
    "current_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Įveskite dabartinį slaptažodį"])},
    "new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Įveskite naują slaptažodį"])},
    "confirm_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patvirtinkite naują slaptažodį"])},
    "password_changed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slaptažodis sėkmingai pakeistas"])},
    "wrong_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neteisingas slaptažodis"])},
    "passwords_not_match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slaptažodžiai nesutampa"])}
  },
  "certificates": {
    "empty_result_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neturite jokių sertifikatų"])},
    "empty_result_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prašome nueiti į kursų sąrašą ir užsiregistruoti kursui, kuris jums būtų naudingas"])},
    "empty_result_redirect_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eiti į kursų sąrašą"])}
  },
  "grades": {
    "grade_text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Įvertinimas: ", _interpolate(_named("grade"))])},
    "topic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tema"])},
    "calculated_weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apskskaičiuotas svoris"])},
    "contribution_to_course_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indėlis į bendrą kurso rezultatą"])},
    "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atsiliepimas"])},
    "percentage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procentai"])},
    "grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Įvertinimas"])},
    "course_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bendrasis kurso įvertis"])},
    "category_total": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("category")), " bendroji suma"])}
  }
}