<template>
  <div>
    <Navigation />
    <CourseHeader
      v-if="course"
      :course="course"
      :back-route="backRoute"
      :paid="false"
    >
      <div
        v-if="course.products"
        class="course-header__header-add-to-cart"
        :class="{ sticky: isAddToCartSticky }"
      >
        <CoursePrice
          v-if="coursePrice"
          :big="!isAddToCartSticky"
          :price="coursePrice.price_formated"
          :special="coursePrice.special_formated"
        />
        <div class="actions">
          <CourseAddToFavourites
            :data="course"
            :dark="!!isAddToCartSticky"
            :border="true"
            class="course__add-to-favorites"
          />
          <custom-button
            v-if="isMobile && course.products.length === 1"
            class="course-header__add-to-cart"
            type="dark"
            :margin-top="0"
            size="md"
            @click="addToCart"
          >
            <template #text>
              <div class="caption">
                <span v-text="$t('common.add_to_cart')" />
                <img src="@/assets/menu/cart-white.svg" alt="Cart" />
              </div>
            </template>
          </custom-button>
        </div>
      </div>
    </CourseHeader>

    <CourseSidebar v-if="isMobile" :course="course" />

    <div class="tabs">
      <div class="container">
        <div class="tabs__wrap">
          <router-link
            exact-active-class="tabs__tab--active"
            :to="{
              name: ROUTE.Course.About,
              params: { id: route.params.id },
            }"
            class="tabs__tab"
          >
            {{ $t("course.about_course") }}
          </router-link>
          <router-link
            exact-active-class="tabs__tab--active"
            :to="{
              name: 'course.subjects',
              params: { id: route.params.id },
            }"
            class="tabs__tab"
          >
            {{ $t("course.subjects") }}
          </router-link>
          <router-link
            exact-active-class="tabs__tab--active"
            :to="{ name: 'course.reviews', params: { id: route.params.id } }"
            class="tabs__tab"
          >
            {{ $t("course.reviews") }}
          </router-link>
          <router-link
            exact-active-class="tabs__tab--active"
            :to="{
              name: 'course.faq',
              params: { id: route.params.id },
            }"
            class="tabs__tab"
          >
            {{ $t("course.faq") }}
          </router-link>
        </div>
      </div>
    </div>
    <div class="course-page">
      <div class="container">
        <div class="course-page__row">
          <div class="course-page__column">
            <div class="course-page__content">
              <slot name="content" />
            </div>
          </div>
          <CourseSidebar v-if="!isMobile" :course="course" />
        </div>
      </div>
    </div>
    <CourseForLargerGroups />
    <PopularCourses />
    <Footer />
  </div>
</template>

<script setup>
import Footer from "@/components/Footer";
import Navigation from "@/components/Navigation";
import CourseForLargerGroups from "@/components/course/CourseForLargerGroups";
import CourseHeader from "@/components/course/CourseHeader.vue";
import CoursePrice from "@/components/course/CoursePrice.vue";
import { useRoute } from "vue-router";
import { computed, onBeforeMount } from "vue";
import useScrollPos from "@/util/useScrollPos";
import useIsMobile from "@/util/useIsMobile";
import CourseSidebar from "@/components/course/CourseSidebar.vue";
import PopularCourses from "@/components/course/PopularCourses.vue";
import CustomButton from "@/components/CustomButton.vue";
import { useCartStore } from "@/store";
import { ROUTE } from "@/router/routenames";
import CourseAddToFavourites from "@/components/course/CourseAddToFavourites.vue";
import { useFavouriteCourses } from "@/store/modules/favouriteCourses";

const route = useRoute();

const props = defineProps({
  course: { type: Object, required: true },
  backRoute: { type: String, default: ROUTE.Discover },
});

const { isMobile } = useIsMobile();
const cartStore = useCartStore();
const favouriteCoursesStore = useFavouriteCourses();

const { add } = cartStore;
const { scrollPosition } = useScrollPos();

const coursePrice = computed(() => {
  if (props.course?.products.length < 1) {
    return null;
  }
  if (props.course?.products.length === 1) {
    return {
      price_formated: props.course.products[0].price_formated,
      special_formated: props.course.products[0].special?.price_formated,
    };
  }

  const productsByLowestOriginalPrice = structuredClone(
    props.course.products,
  ).sort((a, b) => parseFloat(a.price) - parseFloat(b.price));
  const productsByHighestOriginalPrice = structuredClone(
    props.course.products,
  ).sort((a, b) => parseFloat(b.price) - parseFloat(a.price));

  const isAnyProductWithDiscount =
    productsByLowestOriginalPrice.filter((product) => !!product?.special?.price)
      .length > 0;

  const pricesByLowest = combinedSorting(props.course.products, "lowest").map(
    (product) => product?.special?.price_formated || product.price_formated,
  );
  const pricesByHighest = combinedSorting(props.course.products, "highest").map(
    (product) => product?.special?.price_formated || product.price_formated,
  );
  const specialPrice = isAnyProductWithDiscount
    ? pricesByLowest[0] === pricesByHighest[0]
      ? pricesByLowest[0]
      : pricesByLowest[0] + " - " + pricesByHighest[0]
    : null;
  const price =
    productsByLowestOriginalPrice[0].price ===
    productsByHighestOriginalPrice[0].price
      ? productsByLowestOriginalPrice[0].price_formated
      : productsByLowestOriginalPrice[0].price_formated +
        " - " +
        productsByHighestOriginalPrice[0].price_formated;

  return {
    price_formated: price,
    special_formated: specialPrice === price ? null : specialPrice,
  };
});

const isAddToCartSticky = computed(
  () => isMobile.value && scrollPosition.value > 320,
);

const combinedSorting = (products, order) => {
  return structuredClone(products).sort((a, b) => {
    const priceA = a.special?.price ?? a.price;
    const priceB = b.special?.price ?? b.price;

    if (order === "lowest") {
      return parseFloat(priceA) - parseFloat(priceB);
    } else if (order === "highest") {
      return parseFloat(priceB) - parseFloat(priceA);
    } else {
      throw new Error("Invalid order parameter");
    }
  });
};

const addToCart = () => {
  if (!props.course.products.length) {
    return;
  }
  add(props.course.products[0]?.product_id);
};

onBeforeMount(() => favouriteCoursesStore.request());
</script>

<style lang="scss" scoped>
.tabs {
  border-bottom: 1px solid $color-light-grey;
  position: sticky;
  z-index: 4;
  background-color: #fff;
  top: $header-height;

  @media screen and (max-width: $breakpoint-xl) {
    top: 0;
  }

  @media screen and (max-width: $breakpoint-lg) {
    overflow-x: scroll;
  }

  .container {
    padding: 0;

    @media screen and (max-width: $breakpoint-lg) {
      overflow-x: scroll;
      overflow-y: hidden;
    }
  }

  :deep(.container) {
    padding: 0;

    @media screen and (max-width: $breakpoint-lg) {
      overflow-x: scroll;
      overflow-y: hidden;
    }
  }

  &__wrap {
    display: flex;
    align-items: center;
    gap: 40px;
    position: relative;
    padding: 0 40px;

    @media screen and (max-width: $breakpoint-lg) {
      gap: 30px;
      padding: 0 30px;
    }
  }

  &__tab {
    display: block;
    padding: 19px 0;
    position: relative;

    color: $color-menu-item;
    font-family: $font-family;
    font-size: $font-size-sm;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 23px;
    text-decoration: none;
    white-space: nowrap;
    border-bottom: 3px solid transparent;

    &:last-of-type {
      @media screen and (max-width: $breakpoint-lg) {
        padding-right: 15px;
      }
    }

    @media screen and (max-width: $breakpoint-lg) {
      text-wrap: nowrap;
      width: 100%;
      padding: 8px 0px;
      text-align: center;
    }

    &--active {
      border-bottom: solid 3px $color-blue;
      font-weight: 500;
      color: $color-blue;
    }

    &:hover {
      border-bottom: solid 3px $color-blue;
      color: $color-blue;
    }
  }
}

.course-page {
  &__section-error {
    color: $color-blue;
    font-family: $font-family;
    font-size: $font-size-sm;
    letter-spacing: 0;
    line-height: 28px;
    text-align: left;
    margin-bottom: 20px;
  }

  &__section {
    margin-bottom: 60px;
  }

  &__column {
    &--sidebar {
      position: sticky;
      top: 0;

      @media screen and (max-width: $breakpoint-lg) {
        position: relative;
      }
    }
  }

  &__section-header {
    color: $color-blue;
    font-family: $font-family;
    font-size: $font-size-lg;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 40px;
    text-align: left;
    margin-bottom: 20px;
    margin-top: 40px;
  }

  &__section-accordion-trigger {
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__section-content {
    p {
      color: $color-blue;
      font-family: $font-family;
      font-size: $font-size-sm;
      letter-spacing: 0;
      line-height: 28px;

      :deep(p) {
        margin-bottom: 10px;
      }
    }

    h4 {
      margin-top: 40px;
      margin-bottom: 15px;

      color: $color-blue;
      font-family: $font-family;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 26px;
    }

    a {
      color: $color-primary;
      font-family: $font-family;
      font-size: $font-size-sm;
      letter-spacing: 0;
      line-height: 28px;
    }

    h3 {
      color: $color-blue;
      font-family: $font-family;
      font-size: $font-size-slg;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 25px;
    }
  }

  &__row {
    display: flex;
    align-items: flex-start;
    margin-top: 40px;
    gap: 43px;
    @media screen and (max-width: $breakpoint-lg) {
      flex-wrap: wrap;
    }
  }

  &__column {
    flex-basis: 100%;
  }
}

.accordion__trigger {
  img {
    transition: all 0.5s;
  }
}

.accordion__trigger_active {
  img {
    transform: rotate(181deg);
  }

  h3 {
    opacity: 1;
    color: $color-blue !important;
  }
}

.course-header__header-add-to-cart {
  .add-to-cart-plus {
    display: flex;
    flex-direction: row;
  }

  .actions {
    display: flex;
    align-items: center;
    gap: 30px;
    padding: 50px 0;
  }

  &.sticky {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    padding: 10px 16px;
    z-index: 10;
    border-top: 1px solid $color-menu-item;

    .caption {
      display: flex;
      align-items: center;
      gap: 20px;
      justify-content: center;
      padding: 0;
    }

    .actions {
      gap: 10px;
      padding: 0;

      button {
        background-color: $color-primary;
        color: $color-white;
        width: 200px;
      }
    }
  }
}

.course-header {
  &__add-to-cart {
    background-color: $color-white;
    padding: 15px;
    width: 100%;
    border: unset;
    color: $color-primary;
    font-weight: 500;
    font-size: $font-size-md;
    display: block;
  }
}

.accordion {
  display: flex;
  flex-direction: column;
  gap: 0;
  @media screen and (max-width: $breakpoint-lg) {
    margin-bottom: 10px;
  }

  &__trigger_active {
    .accordion__header {
      &:after {
        background: url("../../assets/icons/minus-blue.svg");
        height: 22px;
        width: 22px;
      }
    }

    .accordion__content {
      display: block;
      margin-bottom: 0px;
    }
  }

  &__header {
    text-decoration: none;
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;

    h4 {
      color: $color-blue;
      font-family: $font-family;
      font-size: 16px;
      font-weight: 500;
      margin: 20px 0;
    }

    &:after {
      position: absolute;
      content: "";
      background: url("../../assets/icons/plus-blue.svg");
      height: 22px;
      width: 22px;
      right: 0;
    }
  }

  &__item {
    border-bottom: 1px solid $color-light-grey;
  }
}
</style>
