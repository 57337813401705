<template>
  <div class="menu-mobile">
    <MenuMobileNavbar />
    <ul class="menu-mobile__menu">
      <MenuMobileItem
        v-if="!isShoppingEnabled()"
        :to="{ name: 'home' }"
        :label="$t('navigation.home')"
      />
      <MenuMobileItem
        v-if="isShoppingEnabled()"
        :to="{ name: ROUTE.Discover }"
        :label="$t('megamenu.private.title')"
      />
      <MenuMobileItem
        v-if="isShoppingEnabled()"
        :to="{ name: 'business-and-institutions' }"
        :label="$t('megamenu.business.title')"
      />
      <MenuMobileItem :to="{ name: 'about' }" :label="$t('navigation.about')" />
      <MenuMobileItem
        :to="{ name: 'howitworks' }"
        :label="$t('navigation.how-it-works')"
      />
      <MenuMobileItem
        v-if="isShoppingEnabled()"
        :to="{ name: 'page', params: { id: aboutPage } }"
        :label="$t('footer.hybridlab.about')"
      />
      <MenuMobileDropdown
        v-if="isShoppingEnabled()"
        :label="$t('navigation.projects.dropdown')"
        :items="[
          {
            to: { name: 'projects.in-progress' },
            label: $t('navigation.projects.in_progress'),
          },
          {
            to: { name: 'projects.done' },
            label: $t('navigation.projects.done'),
          },
        ]"
      />
      <MenuMobileItem
        v-if="!isShoppingEnabled()"
        :to="{ name: ROUTE.Dashboard.Courses }"
        :label="$t('navigation.courses')"
      />
      <MenuMobileDropdown
        v-else
        :label="$t('navigation.courses')"
        :items="[
          {
            to: { name: ROUTE.Dashboard.Courses },
            label: $t('common.my_courses'),
          },
          {
            to: { name: 'discover' },
            label: $t('common.all_courses'),
          },
        ]"
      />
      <MenuMobileLocale />
    </ul>
  </div>
</template>

<script setup>
import MenuMobileItem from "./MenuMobileItem.vue";
import MenuMobileNavbar from "./MenuMobileNavbar.vue";
import { onBeforeUnmount, onMounted } from "vue";
import MenuMobileLocale from "./MenuMobileLocale.vue";
import { ROUTE } from "@/router/routenames";
import { useRouter } from "vue-router";
import { usePopupStore } from "@/store/modules/popup.store";
import MenuMobileDropdown from "./MenuMobileDropdown.vue";
import { isShoppingEnabled } from "@/util/helpers";
import { SCREEN_BREAKPOINT_XL } from "@/util/constants";

const router = useRouter();
const aboutPage = process.env.VUE_APP_ABOUT_PAGE;
const popupStore = usePopupStore();
const responsive = window.matchMedia(
  "screen and (max-width: " + SCREEN_BREAKPOINT_XL + "px)",
);

function handleNavigation(to, from, next) {
  popupStore.closeMenu();
  next();
}

onMounted(() => {
  document.querySelector("#app").classList.add("menu-mobile--body-hidden");
  responsive.addEventListener("change", popupStore.closeMenu);
});

const unsubscribe = router.beforeEach(handleNavigation);
onBeforeUnmount(() => {
  document.querySelector("#app").classList.remove("menu-mobile--body-hidden");
  responsive.removeEventListener("change", popupStore.closeMenu);
  unsubscribe();
});
</script>

<style lang="scss" scoped>
.menu-mobile {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: $color-white;
  z-index: $z-index-menu;
  font-family: $font-family;
  overflow-y: scroll;

  display: flex;
  flex-direction: column;

  &__menu {
    list-style: none;
    margin: 0;
    display: flex;
    flex-direction: column;
    padding: 10px 0 0;
  }
}
</style>
