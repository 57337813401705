import backService from "@/plugins/service";
import { defineStore } from "pinia";
import { ref } from "vue";
import { getPurchasedStore } from "@/store/modules/purchased.store";

export const useGradesStore = defineStore("gradesStore", () => {
  const purchasedStore = getPurchasedStore();

  const data = ref([]);

  const request = async () => {
    const courseId = purchasedStore.course.courseId;
    if (!courseId) {
      return;
    }

    const { data: response } = await backService.get(
      `/course/${courseId}/user-grades`,
    );

    data.value = response;
  };

  return { data, request };
});
