import backService from "@/plugins/service";
import { defineStore } from "pinia";
import defaultImage from "@/assets/picture.png";
import { readFileDataURL, restoreLocalStorageJSON } from "@/plugins/misc";
import { useAlertStore } from "./alert.store";
import { useAccountStore } from "@/store/modules/account.store";

const LOCAL_STORAGE_KEY = "SETTINGS_DATA";

export const useSettingsStore = defineStore({
  id: "settings",
  state() {
    return {
      loading: false,
      error: null,
      data: restoreLocalStorageJSON(LOCAL_STORAGE_KEY),
      update: null,
    };
  },
  getters: {
    values() {
      return {
        image: defaultImage,
        organisation_id: null,
        organisation: null,
        position_id: null,
        position: null,
        timezone: "",
        country: "",
        city: "",
        gender: "male",
        date_of_birth: "",
        iframe_url: null,
        logout_iframe_url: null,
        learning_system_url: "",
        learning_system_url_text: "",
        ...(this.data || {}),
      };
    },
  },
  actions: {
    reset() {
      localStorage.removeItem(LOCAL_STORAGE_KEY);

      this.data = null;
    },
    async request() {
      this.loading = true;

      try {
        const { data } = await backService.get("/settings");

        this.loading = false;
        this.error = null;
        this.data = data;

        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(data));

        return true;
      } catch (error) {
        this.loading = false;
        this.error = error;

        return false;
      }
    },
    async updateSettings(values) {
      const alertStore = useAlertStore();
      const accountStore = useAccountStore();

      this.loading = true;

      try {
        const { data } = await backService.post("/settings", values);

        this.update = data;
        accountStore.setNotSetup(null);

        alertStore.success("Pavyko", data.message);

        return this.request();
      } catch (error) {
        this.loading = false;
        this.error = error;

        return false;
      }
    },
    async loadImage(image) {
      this.loading = true;

      try {
        const data = await readFileDataURL(image);

        this.loading = false;
        this.error = null;
        this.data = { ...(this.data || this.values), image: data };

        return true;
      } catch (error) {
        this.loading = false;
        this.error = error;

        return false;
      }
    },
    async updateImage(image) {
      const alertStore = useAlertStore();

      this.loading = true;

      try {
        const formData = new FormData();
        formData.append("image", image);

        const { data } = await backService.post("/settings/image", formData);

        this.update = data;

        alertStore.success("Pavyko", data.message);

        return this.loadImage(image);
      } catch (error) {
        this.loading = false;
        this.error = error;

        return false;
      }
    },
  },
});
