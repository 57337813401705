<template>
  <DashboardLayout
    :is-mobile-shop="isMobileShop"
    :show-navigation="true"
    :show-header-block="true"
    :title="$t('common.my_certificates')"
  >
    <div>
      <div class="container">
        <div class="certificates">
          <div v-show="!isSearchEnabled" class="certificates__header">
            <div class="certificates__header-column">
              <div class="certificates__settings">
                <div v-if="!isMobile" class="certificates__settings-label">
                  <SwitchField @change="validCertificates()" />
                  <span
                    class="certificates__settings__valid-certificates"
                    v-text="$t('common.show_valid_certificates')"
                  />
                </div>
                <div class="certificates__settings-label">
                  <button @click="enableSearch">
                    <img
                      src="../../../assets/menu/search-grey.svg"
                      alt="search"
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <SearchForm
            v-show="isSearchEnabled"
            :placeholder="$t('navigation.search_certificates_placeholder')"
            class="certificates__header"
            @search="search"
            @disable:search="cancel"
          />
          <div v-if="isMobile" class="valid-certificates-wrapper">
            <span
              class="certificates__settings__valid-certificates"
              v-text="$t('common.show_valid_certificates')"
            />
            <SwitchField @change="validCertificates()" />
          </div>
          <div v-if="filtered.length" class="certificates__wrap">
            <Loading v-if="loading" />
            <CertificateItem
              v-for="certificate in filtered"
              :key="certificate.id"
              :certificate="certificate"
            />
          </div>
          <NoResults
            v-else
            :image="CertificateSvg"
            :title="$t('certificates.empty_result_title')"
            :description="$t('certificates.empty_result_description')"
            :back-button-text="$t('certificates.empty_result_redirect_text')"
            :back-button-route="ROUTE.Dashboard.Courses"
          />
        </div>
      </div>
    </div>
    <Footer />
  </DashboardLayout>
</template>

<script>
import CertificateItem from "@/components/dashboard/CertificateItem";
import SwitchField from "@/components/form/SwitchField";
import Footer from "@/components/Footer";
import Loading from "@/components/Loading";
import backService from "@/plugins/service";
import moment from "moment";
import { ref } from "vue";
import SearchForm from "@/components/search/SearchForm.vue";
import useIsMobile from "@/util/useIsMobile";
import NoResults from "@/components/NoResults.vue";
import Dashboard from "@/views/pages/dashboard/Dashboard.vue";
import CertificateSvg from "@/assets/no-results/certificate.svg";
import { ROUTE } from "@/router/routenames";
import DashboardLayout from "@/views/pages/dashboard/DashboardLayout.vue";
import { isShoppingEnabled } from "@/util/helpers";

export default {
  components: {
    DashboardLayout,
    NoResults,
    SearchForm,
    CertificateItem,
    SwitchField,
    Footer,
    Loading,
  },
  setup() {
    const isSearchEnabled = ref(false);
    const { isMobile } = useIsMobile();

    return { isSearchEnabled, isMobile };
  },
  data() {
    return {
      valid: false,
      loading: false,
      certificates: [],
      filtered: [],
      searchString: "",
    };
  },
  computed: {
    ROUTE() {
      return ROUTE;
    },
    Dashboard() {
      return Dashboard;
    },
    CertificateSvg() {
      return CertificateSvg;
    },
    isMobileShop() {
      return this.isMobile && isShoppingEnabled();
    },
  },
  watch: {
    searchString: {
      immediate: true,
      async handler() {
        this.filtered = this.certificates.filter((certificate) => {
          return certificate.title
            .toLowerCase()
            .includes(this.searchString.toLowerCase());
        });
        if (this.valid) {
          this.filterValidCertificates();
        }
      },
    },
    isMobileShop: function () {
      this.isSearchEnabled = this.isMobileShop;
    },
  },
  created() {
    this.getSertificates();
  },
  methods: {
    enableSearch() {
      this.isSearchEnabled = true;
    },
    search(value) {
      this.searchString = value;
    },
    cancel() {
      this.searchString = "";
      this.isSearchEnabled = false;
    },
    validCertificates() {
      this.valid = !this.valid;
      if (this.valid) {
        this.filterValidCertificates();
      } else {
        this.filtered = this.certificates;
      }
    },
    filterValidCertificates() {
      this.filtered = this.filtered.filter((certificate) => {
        const endDate = moment(certificate.valid.to),
          todayDate = moment();

        const difference = todayDate.diff(endDate, "days");

        return difference <= 0;
      });
    },
    async getSertificates() {
      let vm = this;
      this.loading = true;
      await backService
        .get("/certificates")
        .then((response) => {
          vm.loading = false;
          vm.certificates = response.data;
          vm.filtered = response.data;
        })
        .catch(() => {
          vm.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.certificates {
  padding-top: 37px;
  padding-bottom: 80px;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 36px;
    width: 100%;

    @media only screen and (max-width: $breakpoint-lg) {
      margin-bottom: 20px;
    }

    :deep(.search-wrap) {
      width: 100%;
    }

    h3 {
      color: $color-primary;
      font-family: $font-family;
      font-size: $font-size-lg;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 40px;
    }
  }

  &__settings {
    display: flex;
    align-items: center;
    gap: 33px;
  }

  &__settings-label {
    color: $color-blue;
    font-family: $font-family;
    font-size: $font-size-sm;
    letter-spacing: 0;
    line-height: 23px;
    display: flex;
    align-items: center;

    button {
      background: unset;
      border: unset;
    }
  }

  &__settings__valid-certificates {
    color: $color-menu-item;
    font-family: $font-family;
    font-size: $font-size-xxxs;
    letter-spacing: 0;
    line-height: 23px;
    padding-left: 15px;
  }

  &__column {
    display: flex;
    align-items: center;
    @media screen and (max-width: 900px) {
      flex-direction: column;
    }

    &--switch {
      gap: 40px;
      @media screen and (max-width: 900px) {
        gap: 10px;
      }
    }
  }

  &__wrap {
    display: grid;
    grid-template-columns: auto auto auto auto;
    column-gap: 18px;
    row-gap: 50px;

    @media screen and (max-width: $breakpoint-xl) {
      grid-template-columns: auto auto auto;
    }

    @media screen and (max-width: $breakpoint-lg) {
      grid-template-columns: auto auto;
    }
  }
}

.valid-certificates-wrapper {
  text-align: right;
  padding-bottom: 20px;

  span {
    padding-right: 20px;
  }
}
</style>
