<template>
  <div class="course-page__row">
    <div class="course-page__column">
      <div class="course-page__content">
        <div class="course-page__section">
          <GlocareBanner />
          <h3
            v-if="
              purchasedStore.course.overview &&
              purchasedStore.course.overview.title
            "
            v-dompurify-html="
              $t(`course.${purchasedStore.course.overview.title}`)
            "
            class="course-page__section-header"
          />
          <div
            v-dompurify-html="purchasedStore.course.overview.description"
            class="course-page__section-description"
          />
          <div
            v-if="coursePurpose"
            v-dompurify-html="coursePurpose.answer"
            class="course-page__section-description"
          />
          <div
            v-if="courseTasks"
            v-dompurify-html="courseTasks.answer"
            class="course-page__section-description"
          />
          <div v-if="purchasedStore.course.activities" class="subjects">
            <CourseSubjectItem
              :key="purchasedStore.course.activities?.id"
              pages-first
              :subject="purchasedStore.course.activities"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="course-page__column course-page__column--sidebar">
      <CourseSidebarDetails
        v-if="purchasedStore.course"
        :course="purchasedStore.course"
      />
    </div>
  </div>
</template>

<script>
import CourseSidebarDetails from "@/components/course/CourseSidebarDetails";
import {
  COURSE_PURPOSE,
  COURSE_TASKS,
  getPurchasedStore,
} from "@/store/modules/purchased.store";
import GlocareBanner from "@/components/GlocareBanner.vue";
import { computed } from "vue";
import CourseSubjectItem from "@/components/course/CourseSubjectItem.vue";
import { useRoute } from "vue-router";

export default {
  components: {
    CourseSubjectItem,
    CourseSidebarDetails,
    GlocareBanner,
  },
  setup() {
    const purchasedStore = getPurchasedStore();
    const route = useRoute();

    const coursePurpose = computed(() =>
      purchasedStore.course.overview?.items?.find(
        (item) => item.question === COURSE_PURPOSE,
      ),
    );
    const courseTasks = computed(() =>
      purchasedStore.course.overview?.items?.find(
        (item) => item.question === COURSE_TASKS,
      ),
    );

    return {
      coursePurpose,
      courseTasks,
      purchasedStore,
      route,
    };
  },
  data() {
    return {
      certificate: false,
      faqs: [],
    };
  },
  watch: {
    "route.params.id": {
      immediate: true,
      async handler() {
        this.purchasedStore.request(this.route.params.id);
      },
    },
  },
};
</script>

<style lang="scss">
.course-page {
  position: relative;

  .container {
    padding: 0 10px;
  }

  &__section {
    margin-bottom: 60px;
    font-family: $font-family;
  }

  &__section-header {
    color: $color-blue;
    font-family: $font-family;
    font-size: $font-size-lg;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 40px;
    margin-bottom: 20px;
    margin-top: 40px;

    @media only screen and (max-width: $breakpoint-lg) {
      text-align: center;
      margin-top: 0;
    }
  }

  &__section-description {
    font-size: $font-size-sm;
    line-height: 30px;

    img {
      max-width: 100%;
      height: auto;
    }
  }

  &__section-accordion-trigger {
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__section-content {
    p {
      color: $color-blue;
      font-family: $font-family;
      font-size: $font-size-sm;
      letter-spacing: 0;
      line-height: 28px;
    }

    h4 {
      margin-top: 40px;
      margin-bottom: 15px;

      color: $color-blue;
      font-family: $font-family;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 26px;
    }

    a {
      color: $color-primary;
      font-family: $font-family;
      font-size: $font-size-sm;
      letter-spacing: 0;
      line-height: 28px;
    }

    h3 {
      color: $color-blue;
      font-family: $font-family;
      font-size: $font-size-slg;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 25px;
    }
  }

  &__row {
    display: flex;
    align-items: flex-start;
    gap: 43px;
    margin-top: 40px;
    @media screen and (max-width: 900px) {
      flex-wrap: wrap;
    }
  }

  &__column {
    position: relative;
    width: 100%;
    flex-basis: 100%;

    &--sidebar {
      flex-basis: 100%;
      max-width: 393px;
      @media screen and (max-width: 900px) {
        max-width: initial;
      }
    }
  }
}

.accordion__trigger {
  img {
    transition: all 0.5s;
  }
}

.accordion__trigger_active {
  img {
    transform: rotate(181deg);
  }

  h3 {
    opacity: 1;
    color: $color-blue !important;
  }
}
</style>
