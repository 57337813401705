<template>
  <div class="course-page__row">
    <div class="course-page__column course-page__column--sidebar">
      <Accordion v-if="isMobile">
        <AccordionItem
          :active="true"
          :padding="10"
          @update:is-open="openChange"
        >
          <template #accordion-trigger="{ isOpen }">
            <div class="course-page-accordion__header">
              <h4
                class="course-page-accordion__header-text"
                v-text="$t('common.topics')"
              />
              <img
                :src="isOpen ? AccordionOpenIcon : AccordionCloseIcon"
                alt="accordion icon"
              />
            </div>
          </template>
          <template #accordion-content>
            <CourseTopics
              :topics="purchasedStore.course.theory"
              :current-subject="currentSubject"
              :course="course"
            />
          </template>
        </AccordionItem>
      </Accordion>
      <CourseTopics
        v-else
        :topics="purchasedStore.course.theory"
        :current-subject="currentSubject"
        :course="course"
      />
      <CourseTopics
        v-if="!isAccordionOpen"
        :topics="[currentSubject]"
        :current-subject="currentSubject"
        :course="course"
      />
    </div>
    <div v-if="currentSubject" id="course-subject" class="course-page__column">
      <div class="subject-description">
        <div class="subject-description__header">
          <h3 v-if="currentSubject.title" v-text="currentSubject.title" />
        </div>
        <div class="subject-description__content">
          <div v-dompurify-html="currentSubject.description" />
        </div>
      </div>
      <div v-if="currentSubject.sections" class="subjects">
        <CourseSubjectItem
          v-for="section in currentSubject.sections"
          :key="section?.id"
          :subject="section"
        />
      </div>
      <div v-else class="subjects">
        {{ $t("common.no_subjects") }}
      </div>
    </div>
  </div>
</template>

<script>
import CourseSubjectItem from "@/components/course/CourseSubjectItem.vue";
import Accordion from "@/components/accordion/Accordion.vue";
import { useRoute, useRouter } from "vue-router";
import { useAuth2Store } from "@/store/modules/auth2.store";
import { getPurchasedStore } from "@/store/modules/purchased.store";
import { isShoppingEnabled } from "@/util/helpers";
import useIsMobile from "@/util/useIsMobile";
import CourseTopics from "@/views/pages/CourseTopics.vue";
import AccordionItem from "@/components/accordion/AccordionItem.vue";
import AccordionOpenIcon from "@/assets/icons/accordion-up-grey.svg";
import AccordionCloseIcon from "@/assets/icons/accordion-right-grey.svg";
import { ref } from "vue";

export default {
  components: {
    AccordionItem,
    CourseTopics,
    CourseSubjectItem,
    Accordion,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const auth2Store = useAuth2Store();
    const purchasedStore = getPurchasedStore();
    const { isMobile } = useIsMobile();
    const isAccordionOpen = ref(true);

    return {
      route,
      router,
      auth2Store,
      purchasedStore,
      isMobile,
      AccordionOpenIcon,
      AccordionCloseIcon,
      isAccordionOpen,
    };
  },
  computed: {
    selectedSubjectId() {
      return this.route.params.subject;
    },
    refreshToken() {
      return `${this.$i18n.locale}|${this.auth2Store.token}`;
    },
    currentSubject() {
      return this.selectedSubjectId
        ? this.course?.theory.find(
            (section) => section.id === Number(this.selectedSubjectId),
          )
        : this.course?.theory[0];
    },
    course() {
      return this.purchasedStore.course || null;
    },
  },
  methods: {
    isShoppingEnabled,
    openChange(isOpen) {
      this.isAccordionOpen = isOpen;
    },
  },
};
</script>

<style lang="scss">
.subject-description {
  &__header {
    h3 {
      color: $color-blue;
      font-family: $font-family;
      font-size: $font-size-lg;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 40px;
    }
  }

  &__content {
    color: $color-blue;
    font-family: $font-family;
    font-size: $font-size-md;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 25px;

    img {
      width: 100%;
      height: auto;
    }

    p {
      margin-top: 28px;
      font-weight: 500;
    }

    ul {
      padding-left: 23px;
      font-size: $font-size-sm;
      margin-top: 24px;

      li {
        position: relative;
        list-style: none;

        &:after {
          content: "";
          position: absolute;
          left: -24px;
          top: 7px;
          background: url("../../assets/correct.svg");
          width: 12px;
          height: 12px;
        }
      }
    }
  }
}

.course-page {
  &__row {
    display: flex;
    align-items: flex-start;
    gap: 43px;
    margin-top: 40px;
    @media screen and (max-width: 900px) {
      flex-wrap: wrap;
    }
  }

  &__column {
    flex-basis: 100%;

    .accordion {
      margin-bottom: 0;

      .accordion__trigger {
        margin-bottom: 20px;
      }
    }

    &--sidebar {
      flex-basis: 100%;
      max-width: 393px;
      @media screen and (max-width: 900px) {
        max-width: initial;
      }
    }
  }

  &-accordion__header {
    display: flex;
    justify-content: space-between;
    padding-top: 5px;
    margin-bottom: 17px;

    &-text {
      font-family: $font-family;
      font-size: $font-size-md;
      font-weight: 400;
      line-height: 12px;
      color: $color-primary;
    }

    img {
      transition: unset;
      transform: unset;
    }
  }
}
</style>
