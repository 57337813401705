<template>
  <a
    v-for="lang in localeStore.languages"
    :key="lang"
    :data-lang="lang"
    class="navbar__dropdown-item"
    @click.prevent="changeLanguage(lang)"
  >
    <img :src="getLanguageImage(lang)" alt="Icon" />
    {{ getLanguageCode(lang) }}
  </a>
</template>

<script setup>
import i18n from "@/i18n";
import { useLocaleStore } from "@/store/modules/locale.store";
import { defineProps } from "vue";

const localeStore = useLocaleStore();

const props = defineProps({
  callback: {
    type: Function,
    required: false,
  },
});

const changeLanguage = (lang) => {
  const language = lang.code.split("-");
  i18n.global.locale = language[0];

  if (props.callback && typeof props.callback === "function") {
    props.callback();
  }
};

const getLanguageImage = (lang) => {
  const language = lang.code.split("-");
  return require("@/assets/menu/" + language[0] + "-square.svg");
};

const getLanguageCode = (lang) => {
  return lang.code.split("-")[0].toUpperCase();
};

if (!localeStore.languages.length) {
  localeStore.request();
}
</script>

<style scoped lang="scss">
.navbar__dropdown-item {
  cursor: pointer;
  padding: 3px 34px 3px 0;
  color: $color-menu-item;
  font-size: $font-size-xs;
  font-family: $font-family;
  font-weight: 500;

  img {
    height: 18px;
    width: 18px;
    margin-right: 12px;
  }
}
</style>
