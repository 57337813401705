<template>
  <div :class="['fav', { dark, border, active: isFavourite }]" @click="toggle">
    <img :src="image" alt="Mark as favourite" />
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useRoute } from "vue-router";
import { ROUTE } from "@/router/routenames";
import { useFavouriteCourses } from "@/store/modules/favouriteCourses";
import { storeToRefs } from "pinia";

const props = defineProps({
  data: {
    type: Object,
    default: null,
  },
  dark: {
    type: Boolean,
    default: false,
  },
  border: {
    type: Boolean,
    default: false,
  },
});

const favouriteCoursesStore = useFavouriteCourses();
const route = useRoute();

const { favouriteCourses } = storeToRefs(favouriteCoursesStore);

const isCurrentRouteCourse = computed(() => route.name === ROUTE.Course.About);
const courseId = computed(() =>
  isCurrentRouteCourse.value ? route.params.id : props.data.id?.toString(),
);
const favouriteCourseEntry = computed(() =>
  favouriteCourses.value.find((course) => course.course === courseId.value),
);
const isFavourite = computed(() => !!favouriteCourseEntry.value);
const image = computed(() => {
  if (props.dark) {
    return isFavourite.value
      ? require("../../assets/course/fav_dark_filled.svg")
      : require("../../assets/course/fav_dark.svg");
  }

  return isFavourite.value
    ? require("../../assets/course/fav_white_filled.svg")
    : require("../../assets/course/fav_white.svg");
});

const toggle = () => {
  if (!props.data) {
    return;
  }

  if (!isFavourite.value) {
    favouriteCoursesStore.store(courseId.value);
    return;
  }

  if (!favouriteCourseEntry.value) {
    return;
  }
  favouriteCoursesStore.destroy(favouriteCourseEntry.value.id);
};
</script>

<style scoped lang="scss">
.fav {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 52px;
  height: 52px;
  border-radius: 8px;
  cursor: pointer;

  &.border.dark {
    border: 2px solid $color-primary;
  }

  &.border {
    border: 2px solid #fff;
  }
}
</style>
