<template>
  <div>
    <Navigation />
    <div class="container">
      <div class="faqs">
        <h2 v-dompurify-html="name"></h2>
        <p v-dompurify-html="intro"></p>

        <accordion :padding="34">
          <accordion-item v-for="item in items" :key="item.id">
            <template #accordion-trigger="{ isOpen }">
              <div class="faqs__header">
                <h3 v-dompurify-html="item.question" />
                <img :src="isOpen ? MinusBlueIcon : PlusBlueIcon" alt="svg" />
              </div>
            </template>
            <template #accordion-content>
              <div v-dompurify-html="item.answer" class="faqs__content" />
            </template>
          </accordion-item>
        </accordion>

        <div class="faqs__need-help">
          <span>Vistiek reikia pagalbos?</span>
          <CustomButton
            :type="'default'"
            :text="'Kontaktai'"
            @click="contact()"
          />
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script setup>
import Footer from "@/components/Footer";
import Navigation from "@/components/Navigation";
import CustomButton from "@/components/CustomButton";
import Accordion from "@/components/accordion/Accordion";
import AccordionItem from "@/components/accordion/AccordionItem";
import { useRoute, useRouter } from "vue-router";
import { ref } from "vue";
import backService from "@/plugins/service";
import MinusBlueIcon from "@/assets/icons/minus-blue.svg";
import PlusBlueIcon from "@/assets/icons/plus-blue.svg";

const loading = ref(true);
const name = ref("");
const intro = ref("");
const items = ref([]);
const router = useRouter();
const route = useRoute();

backService
  .get(`/faq/${route.params.category}`)
  .then((response) => {
    loading.value = false;
    name.value = response.data.name;
    intro.value = response.data.intro;
    items.value = response.data.items;
  })
  .catch((error) => {
    console.error(error);
    loading.value = false;
    name.value = "";
    intro.value = "";
    items.value = [];
  });

const contact = () => router.push({ name: "contacts" });
</script>
<style lang="scss" scoped>
.faqs {
  margin-bottom: 80px;

  ul {
    padding: 0px !important;
  }

  h2 {
    color: $color-blue;
    font-family: $font-family;
    font-size: 32px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 53px;
    margin-top: 60px;
  }

  p {
    opacity: 0.5;
    color: $color-blue;
    font-family: $font-family;
    font-size: $font-size-sm;
    letter-spacing: 0;
    line-height: 23px;
    margin-bottom: 40px;
  }

  &__header {
    height: 80px;
    display: flex;
    align-items: center;

    gap: 18px;

    h3 {
      color: $color-blue;
      font-family: $font-family;
      font-size: $font-size-slg;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 25px;
    }
  }

  &__content {
    color: $color-blue;
    font-family: $font-family;
    font-size: $font-size-sm;
    letter-spacing: 0;
    line-height: 30px;
    margin-bottom: 20px;
  }

  &__need-help {
    display: flex;
    align-items: center;
    gap: 20px;

    span {
      color: $color-blue;
      font-family: $font-family;
      font-size: 13px;
      letter-spacing: 0;
      line-height: 21px;
    }
  }
}

.accordion__trigger {
  img {
    transition: all 0.5s;
  }
}

.accordion__trigger_active {
  img {
    transform: rotate(181deg);
  }

  h3 {
    opacity: 1;
    color: $color-blue !important;
  }
}
</style>
