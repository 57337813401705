<template>
  <div>
    <Navigation :is-static="false" />

    <PageHeader
      :image="header?.image"
      :title="header?.title"
      :description="header?.description"
    />
    <div class="map">
      <div class="container">
        <div v-if="chapters[0]" class="content-start-block">
          <h3 v-dompurify-html="chapters[0].title" class="block-title" />
          <p v-dompurify-html="chapters[0].content" class="block-description" />
        </div>

        <div class="map__image">
          <img src="../../assets/map.svg" alt="map" />
        </div>

        <div
          v-if="chapters[1]"
          class="content-end-block"
          :class="{ divider: !hasMultipleChapters }"
        >
          <h3 v-dompurify-html="chapters[1].title" class="block-title" />

          <div class="block-description-parts">
            <div
              v-for="(item, index) in chapters[1].children"
              :key="index"
              class="part-item"
            >
              <div class="part-number" v-text="'0' + (index + 1)" />
              <h4 v-dompurify-html="item.title" class="item-title" />
              <p v-dompurify-html="item.content" class="item-content" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <CompanyTypesSlider v-if="hasMultipleChapters" :content="chapters[2]" />

    <Footer />
  </div>
</template>

<script setup>
import Footer from "@/components/Footer.vue";
import Navigation from "@/components/Navigation.vue";
import PageHeader from "@/components/PageHeader.vue";
import CompanyTypesSlider from "@/components/CompanyTypesSlider.vue";
import { computed, ref, watch } from "vue";
import { usePageStore } from "@/store/modules/page.store";
import i18n from "@/i18n";

const pagesStore = usePageStore();
const header = ref(null);
const chapters = ref([]);

const hasMultipleChapters = computed(() => chapters.value.length > 2);

const showVideo = () => {
  window.location.href =
    "https://www.youtube.com/watch?v=hCx_V5E2UVg&t=12s&ab_channel=Studentams";
};

watch(
  () => i18n.global.locale,
  async () => {
    const { id, chapters: book } = await pagesStore.book("about");
    chapters.value = book;
    header.value = await pagesStore.request(id);
  },
  { immediate: true },
);
</script>

<style lang="scss" scoped>
.divider {
  margin-bottom: 100px;
}

.map {
  margin-top: 60px;
  margin-bottom: 50px;

  @media screen and (max-width: $breakpoint-sm) {
    margin-top: 46px;
  }

  .content-start-block {
    margin-bottom: 50px;

    .block-title {
      color: $color-primary;
      font-size: $font-size-lg;
      font-weight: 500;
      text-align: center;
      margin-bottom: 36px;

      @media screen and (max-width: $breakpoint-sm) {
        margin-bottom: 26px;
      }
    }

    .block-description {
      color: $color-primary;
      font-size: $font-size-md;
      font-weight: 400;
      line-height: 22px;
      text-align: center;
      margin-bottom: 50px;

      @media screen and (max-width: $breakpoint-sm) {
        text-align: left;
      }
    }
  }

  .content-end-block {
    margin-top: 80px;

    .block-title {
      color: $color-primary;
      font-size: $font-size-lg;
      font-weight: 500;
      line-height: 26px;
      text-align: center;
      margin-bottom: 50px;

      .h3 {
        font-size: 22px;
      }
    }

    .block-description-parts {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 50px;
    }

    .part-item {
      position: relative;

      .part-number {
        color: $color-primary;
        font-size: $font-size-xl;
        font-weight: 700;
        line-height: 26px;
        margin-bottom: 26px;
      }

      .item-title {
        color: $color-primary;
        font-size: $font-size-slg;
        font-weight: 500;
        line-height: 26px;
        margin-bottom: 26px;
      }

      .item-content {
        color: $color-primary;
        font-size: $font-size-md;
        font-weight: 400;
        line-height: 22px;
      }

      @media screen and (min-width: 768px) {
        flex: 1;
      }
    }
  }

  &__image {
    display: flex;
    justify-content: center;
    margin-bottom: 24px;

    img {
      max-height: 438px;

      @media screen and (max-width: 900px) {
        max-width: 100%;
      }
    }
  }
}
</style>
